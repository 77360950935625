import { createTheme } from "@mui/material/styles";
import './index.css'

const appTheme = createTheme({
    palette: {
        primary: {
            main: '#D62311'
        },
        background: {
            default: '#ededed'
        },
        secondary: {
            main: '#282c34'
        } 
    },
    typography: {
        fontFamily: 'Mecherle-Sans'
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
                        '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
                            borderColor: '#282c34',
                        },
                    },
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
                        '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
                            borderColor: '#282c34',
                        },
                    },
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#282c34'
                }
            }
        }
    },
})

appTheme.typography.h5 = {
    fontFamily: 'Mecherle-Sans-SemiBold',
    fontSize: 18
}
appTheme.typography.h4 = {
    fontFamily: 'Mecherle-Sans-SemiBold',
    fontSize: 20
}
appTheme.typography.h3 = {
    fontFamily: 'Mecherle-Sans-SemiBold',
    fontSize: 24
}
appTheme.typography.h2 = {
    fontFamily: 'Mecherle-Sans-SemiBold',
    fontSize: 30
}
appTheme.typography.h1 = {
    fontFamily: 'Mecherle-Sans-SemiBold',
    fontSize: 34
}
appTheme.typography.body1 = {
    fontFamily: 'Mecherle-Sans',
    fontSize: 14
}
appTheme.typography.body2 = {
    fontFamily: 'Mecherle-Sans',
    fontSize: 18
}



export { appTheme }