import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Avatar,
  MenuItem,
  Button,
  Popover,
  Tooltip,
} from "@mui/material";
import logo from "../images/SF_Logo_Red.svg";
import { useMsal } from "@azure/msal-react";

const userguidelink =
  "https://statefarm.sharepoint.com/:b:/r/sites/TeamLabs-ComputerVision/Shared%20Documents/General/Public%20Library/Signature%20Type%20Classification/User%20Guides/Signature%20Type%20Validation%20Self-Service%20Portal%20User%20Guide.pdf?csf=1&web=1&e=JbJmKM";
const reportissuelink =
  "https://statefarmprod.service-now.com/now/nav/ui/classic/params/target/com.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3Da20788f193c3869035b9b4ddfaba1040%26sysparm_link_parent%3Dd85c4496db17e0105775e05e139619e2%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_default%26sysparm_view%3Dtext_search";
const contactuslink =
  "mailto:home.DL-InnovGrp-ComputerVision.DL88YN@statefarm.com";

const headerColor = "#282c34";

function Header() {
  const { accounts } = useMsal();
  const alias = accounts[0]['username'].split(".")[2].split("@")[0]
  const name = accounts[0]['name']

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: headerColor }}>
        <Toolbar>
          <Box sx={{ marginRight: "auto" }}>
            <img src={logo} alt="State Farm logo" height="50" />
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, marginLeft: "10px" }}
          >
            Signature Type Classification
          </Typography>

          <Button
            id="help-button"
            sx={{
              width: 120,
              // border: 0,
              // // borderColor: "skyblue",
              height: "40px",
              fontSize: 15,
              fontWeight: 600,
              bgcolor: "white",
              color: headerColor,
              mx: 3,
            }}
            variant="contained"
            onClick={handleClick}
            onMouseOver={handleClick}
            type="help"
          >
            Help
          </Button>
         

       
          <Popover
            sx={{ mt: -1}}
            id="help-menu"
            elevation={0}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slotProps={{
              paper: {

              sx: {
                border: 0,
                borderBottomRadius: 2,
                width: anchorEl && anchorEl.offsetWidth,
                minWidth: 120,
              },
              onMouseLeave: handleClose
            }}}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            disableRestoreFocus
          >
              <MenuItem
                href={userguidelink}
                target="_blank"
                component="a"
                onClick={handleClose}
                sx={{ fontSize: 16, borderRadius: 0, mt:0.5 }}
              >
                User Guide
              </MenuItem>
              <MenuItem
                href={reportissuelink}
                target="_blank"
                component="a"
                onClick={handleClose}
                sx={{ fontSize: 16 }}
              >
                Report Issue
              </MenuItem>
              <MenuItem
                href={contactuslink}
                target="_blank"
                component="a"
                onClick={handleClose}
                sx={{ fontSize: 16, mb:0.5  }}
              >
                Contact Us
              </MenuItem>     
          </Popover>
          <Tooltip title={`Signed in as ${alias}`}>
            <Avatar alt={name} 
              src={`https://my.sfcollab.org/User%20Photos/Profile%20Pictures/OPR_${alias}_LThumb.jpg`}
              children={`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`}/>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
