import './App.css';
import React, { useEffect } from 'react';
import SearchPage from './pages/SearchPage';
import Header from './components/Header';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { appTheme } from './theme.ts';
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";




// import background from "./images/bg-8x8-grid.svg";

function App() {
  // const [loginState, setLoginState] = React.useState(true);
  useEffect(() => {
    document.title = "Signature Processing"
  })

  function ErrorComponent({error}) {
    return <p>An Error Occurred: <pre>{JSON.stringify(error, null, 2)}</pre></p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

  return (

    
        <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest} 
          errorComponent={ErrorComponent} 
          loadingComponent={LoadingComponent}
        >
           <div >
            <ThemeProvider theme={appTheme}>
              <CssBaseline />
              <Header/>
              <SearchPage />
            </ThemeProvider>
          </div>
      </MsalAuthenticationTemplate>
    

  )
}

export default App;
