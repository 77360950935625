
export const msalConfig = {
  auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: process.env.REACT_APP_AUTHORITY_URL,
      redirectUri: process.env.REDIRECT_URL,
  },
};

export const loginRequest = {
  scopes: ["api://0e26ef04-29c4-4f96-8017-181146ae6bca/.default"]
};
