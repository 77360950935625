import React, {useState, useEffect} from 'react'
import { TextField, Box, Typography, Button, Card, Checkbox, CardContent, CardMedia, Accordion, AccordionSummary, AccordionDetails, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Snackbar } from '@mui/material'
import { CheckCircle, Report, Cancel, Help } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "./../authConfig";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';

//2024-01-18-00.29.23.273707 indiana
//2024-01-18-22.07.55.106654 indiana XX
// 2024-01-19-10.21.22.123456 matt v problem child
// 2024-01-19-10.21.22.183796 oklahoma

// Function to sort by the second number in SK
const sortBySecondNumberInSK = (data) => {
    return data.sort((a, b) => {
      const secondNumberA = parseInt(a.SK.split('_')[1]);
      const secondNumberB = parseInt(b.SK.split('_')[1]);
      return secondNumberA - secondNumberB;
    });
};

const SearchPage = () => {
    const {instance, accounts} = useMsal();
    const [bearerToken, setBearerToken] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState(null)
    const [iSeitKey, setKey] = useState("")
    const [classification, setClassification] = useState([])
    const [resultSaved, setResultSaved] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)
    const [regexError, setRegexError] = useState(false)
    const [helpText, setHelpText] = useState("")
    const [radioButtonSelected, setRadioButtonSelected] = useState([])
    //const [badImageSaved, setBadImageSave] = useState([])
    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState([])
    const [snackbarMessage, setSnackbarMessage] = useState("")

    const alias = accounts[0]['username'].split(".")[2].split("@")[0]

    useEffect(() => {
        const accessTokenRequest = {
          scopes: loginRequest.scopes,
          account: accounts[0],
        };

        instance.acquireTokenSilent(accessTokenRequest).then(
            (accessTokenResponse) => {setBearerToken(accessTokenResponse.accessToken)})
    });

    
    const getSignature = () => {
        setResult(null)
        setErrorMessage(null)
        if(testRegex(iSeitKey)){
            setIsLoading(true)
            setRegexError(false)
            const endpointURL = `${process.env.REACT_APP_SIGNATURE_URL}/sigValResults?iseit_key=${iSeitKey}&alias=${alias}`            
            axios.get(endpointURL, { headers: {Authorization: "Bearer " + bearerToken}})
            .then(response => {
                if(response.status === 209){
                    setErrorMessage(response.data.message) 
                }
                else{
                    const sortedResponseData = sortBySecondNumberInSK(response.data)
                    const checkedArray = [];
                    for(let i = 0; i < sortedResponseData.length; i++){
                        checkedArray[i] = sortedResponseData[i]['image_capture_error']
                    }
                    setChecked(checkedArray)
                    // setResult(response.data)
                    setResult(sortedResponseData);
                    setClassification(Array(sortedResponseData.length).fill(" "))
                    setResultSaved(Array(sortedResponseData.length).fill(false))
                    setRadioButtonSelected(Array(sortedResponseData.length).fill(false))
                }
                
            })
            .catch((error) => {
                // console.log(error)
                if(error.hasOwnProperty('response')){
                    if(error.response.status === 400) setErrorMessage("Invalid input. Please ensure you are using a valid iSeit Key") 
                    else if(error.response.status === 404) setErrorMessage(error.response.data.message)
                    else{
                        setErrorMessage("Unknown error. If this continues to occur please contact the Innovation Group Computer Vision team at: DL-InnovGrp-ComputerVision.DL8BYN@internal.statefarm.com")
                    }
                }
                else{
                    setErrorMessage("Unknown error. If this continues to occur please contact the Innovation Group Computer Vision team at: DL-InnovGrp-ComputerVision.DL8BYN@internal.statefarm.com")
                }
            }).finally(() => {
                setIsLoading(false)
                setHelpText("")
            })
        }
        else{
            setRegexError(true)
            setHelpText("Invalid iSeitKey Format")
        }

        
    }

    const postFeedback = (index) => {
        // setResult(null)
        setErrorMessage(null)
        setIsLoading(true)
        const endpointURL = `${process.env.REACT_APP_SIGNATURE_URL}/processFeedback`  
        axios.post(endpointURL, 
            {"doc_id": result[index]["doc_id"], 
            "iseit_key": iSeitKey, 
            "file_num": result[index]["SK"].split('_')[0], 
            "sig_num": result[index]["SK"].split('_')[1], 
            "submitting_user": accounts[0]['username'].split(".")[2].split("@")[0], 
            "original_classification": result[index]["classification"].toLowerCase().trim().replace(" ", "_"), 
            "corrected_classification": classification[index],
            "confidence_scores": result[index]['confidence_scores'],
            "alias": alias},
            { headers: {Authorization: "Bearer " + bearerToken}})
        .then(response => {
            if(response.status === 200){

                const next_resultSaved = resultSaved.slice()
                next_resultSaved[index] = true
                setResultSaved(next_resultSaved)
                setSnackbarMessage("Classification feedback recorded successfully.")
                setOpen(true)
            }
        })
        .catch((error) => {
            setErrorMessage("Unknown error. If this continues to occur please contact the Innovation Group Computer Vision team at: DL-InnovGrp-ComputerVision.DL8BYN@internal.statefarm.com")
            console.log(error);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const postBadImage = (index) => {
        // const nextBadImageSave = badImageSaved.slice()
        // nextBadImageSave[index] = true
        // setBadImageSave(nextBadImageSave)
        setErrorMessage(null)
        setIsLoading(true)
        const nextChecked = checked.slice()
        nextChecked[index] = !checked[index]

        axios.post(`${process.env.REACT_APP_SIGNATURE_URL}/processCaptureError`,
        {
            "doc_id": result[index]["doc_id"], 
            "iseit_key": iSeitKey, 
            "file_num": result[index]["SK"].split('_')[0], 
            "sig_num": result[index]["SK"].split('_')[1],
            "image_capture_error": nextChecked[index].toString(),
            "alias": alias
        }, { headers: {Authorization: "Bearer " + bearerToken}})
        .then(response => {
            if(response.status === 200){
                setChecked(nextChecked)
                setSnackbarMessage("Signature section capture feedack recorded succesfully.")
                setOpen(true)
            }
        })
        .catch((error) => {
            setErrorMessage("Unknown error. If this continues to occur please contact the Innovation Group Computer Vision team at: DL-InnovGrp-ComputerVision.DL8BYN@internal.statefarm.com")
            console.log(error);
        }).finally(() => {
            setIsLoading(false)
        })

        // console.log(nextChecked)
        // const nextChecked = checked.slice()
        // nextChecked[index] = !checked[index]
        //console.log(checked[index])
        
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway'){
            return;
        }
        setOpen(false)
    }

    const handleChange = (event, index) => {
        const next_classification = classification.slice()
        const nextRadioButtonSelected = radioButtonSelected.slice()
        next_classification[index] = event.target.value
        nextRadioButtonSelected[index] = true
        setClassification(next_classification)
        setRadioButtonSelected(nextRadioButtonSelected)
    }
    
    const clearResults = () => {
        setResult(null)
        setKey("")
        setHelpText("")
    }

    const testRegex = (input) => {
        const reg =  /^\d\d\d\d-\d\d-\d\d-\d\d\.\d\d\.\d\d\.\d\d\d\d\d\d$/i;
        return reg.test(input)
    }

    const textInput = (event) => {
        setHelpText("")
        setKey(event.target.value.trim())
    }

    const resultTextRender = (type) =>{
        return{
            'valid': <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                color: '#056A1B'
            }}><Typography gutterBottom variant="h4" component="div">Result: Valid Signature Type</Typography><CheckCircle fontSize='large'/></div>,
            'invalid': <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                color: '#7F0818'
            }}><Typography gutterBottom variant="h4" component="div">Result: Invalid Signature Type - {type}</Typography><Cancel fontSize='large'/></div>,
            'undetermined': <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                color: '#1B41C9'
            }}><Typography gutterBottom variant="h4" component="div">Result: Below Confidence Threshold</Typography><Help fontSize='large'/></div>
        }
    }

    const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        width: "85%",
        height: "60%",
        margin: "auto",
        padding: "2%",
        opacity: 0.8,
        justifyContent: 'center',
        alignItems: 'center',
        // marginTop:'5%'
      };
    
      const mainContentStyle = {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: "auto",
        padding: "2%",
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
      }

      const mainCardStyle = {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: "auto",
        padding: "2%",
        justifyContent: 'space-evenly',
        //alignItems: 'top',
        flexWrap: 'wrap'
      }

    return(
        <div style={mainContainerStyle}>
            <Typography gutterBottom variant="h1" component="div" >Validate Signature</Typography>
            <Box style={mainContentStyle}>
                <TextField 
                    id='searchBar' 
                    placeholder="searchBar"
                    label='iSeit Key' 
                    variant='outlined' 
                    required 
                    style={{width: '40%', paddingRight: '10px'}} 
                    InputLabelProps={{style: { color: '#282c34' },}}
                    onChange={
                        textInput
                        //(event) => {setKey(event.target.value)}
                    }
                    value={iSeitKey}
                    error={regexError}
                    helperText={helpText}
                    onKeyDown={(event) => {
                        if(event.key === 'Enter') getSignature()
                    }}/>
                <Button 
                    sx={{height: '53px', fontSize: 15, fontWeight: 600}}
                    variant='contained'
                    onClick={getSignature}
                    type="submit">
                    Enter
                </Button>
            </Box>
            {errorMessage && <Alert severity="error">
                {errorMessage}
            </Alert>}
            <Typography sx={{ marginBottom: '10px', width: '43%'}} variant='body1'><b>Note:</b> All states with only single signature documents are supported in the portal as of 8/13/2024. For state specific support dates, please reference the user guide (linked in the top right corner in the help menu).</Typography>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}>
                <CircularProgress/>
            </Backdrop>
            <div style={mainCardStyle} data-testid="card-container">
                {result && result.map((res, index) => ( <div key={index} data-testid={`div-${index}`}><Card sx={{ width: 550, margin: '15px' }}>
                <CardMedia
                    sx={{ height: 150, borderBottom: 1, borderTopLeftRadius: '5px', borderTopRightRadius: '5px', borderColor: 'lightGrey' }}
                    image={`data:image/jpeg;base64, ${res['b64image']}`}
                    title="signature"
                />
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexWrap: 'wrap',
                    }}>
                <Checkbox 
                    checked = {checked[index]}
                    //checked = {res['image_capture_error']}
                    onClick={() => postBadImage(index)}
                    color = 'secondary'
                    data-testid={`checkbox-${index}`}
                />
                <Typography component="div">Check here if the signature section of form is not captured in the image above.</Typography>
                </div>
                {/* <FormControlLabel sx={{marginLeft: '5px','&.Mui-checked': {color: 'black'}}} onClick={() => postBadImage(index)} control={<Checkbox />} color="secondary" label="Check box if signature section of form not captured." /> */}
                <CardContent>
                    {resultTextRender(res['classification'])[res['sig_validity'].toLowerCase()]}
                    <Typography gutterBottom variant="body2" component="div">
                        iSEIT Key: {res['iseit_key']}
                    </Typography>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        // marginBottom: '15px'
                    }}>
                        
                        {/* <Button 
                            variant='contained'
                            onClick={() => postBadImage(index)}
                            disabled={badImageSaved[index]}>
                            Click here if the image capture is bad.
                        </Button> */}
                    </div>
                    {/* <Typography gutterBottom variant="body2" component="div">
                        State: {res['state']}
                    </Typography> */}
                </CardContent>
                <Accordion defaultExpanded={res['sig_validity'].toLowerCase() === 'undetermined'}> 
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{alignItems: 'center'}}>
                        <Report/><Typography variant='body2' sx={{marginLeft: '5px'}} component="div">{res['sig_validity'].toLowerCase() !== 'undetermined' ? <div>Disagree? Which type of signature do you think it is?</div> : <div>Select signature classification below.</div>}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl fullWidth data-testid={`feedback-${index}`}>
                            {res['sig_validity'].toLowerCase() === 'valid' && 
                            <div>
                                <FormLabel id="controlled-radio-classification-buttons-group">Classification</FormLabel>
                                <RadioGroup
                                    row
                                    disabled={resultSaved}
                                    aria-labelledby="controlled-radio-invalid-classification-buttons-group"
                                    name="radio-invalid-classification-buttons-group"
                                    value={classification[index]}
                                    onChange={(e) => handleChange(e, index)}
                                >   
                                    <FormControlLabel value="adobe_font" control={<Radio />} label="Adobe Font" disabled={resultSaved[index]}/>
                                    <FormControlLabel value="adobe_signature" control={<Radio />} label="Adobe Signature" disabled={resultSaved[index]}/>
                                    <FormControlLabel value="blank" control={<Radio />} label="Blank" disabled={resultSaved[index]}/>
                                </RadioGroup>
                            </div>
                            }
                            {res['sig_validity'].toLowerCase() === 'invalid' && 
                            <div>
                            <FormLabel id="controlled-radio-classification-buttons-group">Classification</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="controlled-radio-valid-classification-buttons-group"
                                name="radio-valid-classification-buttons-group"
                                value={classification[index]}
                                onChange={(e) => handleChange(e, index)}
                            >   
                                <FormControlLabel value="docusign" control={<Radio />} label="DocuSign" disabled={resultSaved[index]}/>
                                <FormControlLabel value="inkpen" control={<Radio />} label="Inkpen" disabled={resultSaved[index]}/>
                                {/* <FormControlLabel value="adobe" control={<Radio />} label="Adobe" disabled={resultSaved}/>
                                <FormControlLabel value="blank" control={<Radio />} label="Blank" disabled={resultSaved}/> */}
                            </RadioGroup>
                            </div>
                            }
                            {res['sig_validity'].toLowerCase() === 'undetermined' && 
                            <div>
                            <FormLabel id="controlled-radio-classification-buttons-group">Classification</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="controlled-radio-valid-classification-buttons-group"
                                name="radio-valid-classification-buttons-group"
                                value={classification[index]}
                                onChange={(e) => handleChange(e, index)}
                            >   
                                <FormControlLabel value="docusign" control={<Radio />} label="DocuSign" disabled={resultSaved[index]}/>
                                <FormControlLabel value="inkpen" control={<Radio />} label="Inkpen" disabled={resultSaved[index]}/>
                                <FormControlLabel value="adobe_signature" control={<Radio />} label="Adobe Signature" disabled={resultSaved[index]}/>
                                <FormControlLabel value="adobe_font" control={<Radio />} label="Adobe Font" disabled={resultSaved[index]}/>
                                <FormControlLabel value="blank" control={<Radio />} label="Blank" disabled={resultSaved[index]}/>
                                <FormControlLabel value="unsure" control={<Radio />} label="Unsure" disabled={resultSaved[index]}/>
                            </RadioGroup>
                            </div>
                            }
                        </FormControl>
                        <Button 
                            sx={{marginTop: '10px'}} 
                            variant='contained'
                            onClick={() => postFeedback(index)}
                            disabled={resultSaved[index] || !radioButtonSelected[index]}>
                                Save
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </Card>
        
        </div>))}</div>
        {result && <div>
         <Button 
            sx={{marginTop: '10px', float: 'right'}} 
            variant='contained'
            onClick={clearResults}
            >
                Clear Results
        </Button>
        </div>}
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            >
             <Alert
                onClose={handleSnackbarClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
        </div>
    )
        


}


export default SearchPage