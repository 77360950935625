import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";



// MSAL imports
//import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";


const pca = new PublicClientApplication(msalConfig);

const AppWithAuthentication = () => (
  <MsalProvider instance={pca}>
      <App />
  </MsalProvider>
);

await pca.initialize()
const root = createRoot(document.getElementById("root")); 
root.render(<AppWithAuthentication />);